import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import useApi from "../services/useApi";
import {useAuth} from "./AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";

const BotContext = createContext();

export const BotProvider = ({ children }) => {

    const {authenticated, user, setUser} = useAuth()
    const {loading, data, fetchData, success} = useApi()
    const [mounted, setMounted] = useState(false)
    const [categories, setCategories] = useState([])
    const [prices, setPrices] = useState()
    const [prices2, setPrices2] = useState()
    const [senshyPricesTelegram, setSenshyPricesTelegram] = useState()
    const [senshyPricesInstagram, setSenshyPricesInstagram] = useState()
    const [affiliateId, setAffiliateId] = useState()
    const navigate = useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');

    useEffect(() => {
        authenticated && !mounted && fetchData("/home")
    }, [authenticated, mounted])

    useEffect(() => {
        if(data) {
            setMounted(true)
            setCategories(data.category)
            setPrices(data.prices)
            setPrices2(data.prices2)
            setSenshyPricesTelegram(data.senshy_prices_telegram)
            setSenshyPricesInstagram(data.senshy_prices_instagram)
            setAffiliateId(data.affiliate_id)
            setUser(data.user)

            // !source && user.remainder === null && !user.product1 && navigate("/payment")
            // user.remainder === null && navigate("/payment")
            user.remainder === null && user.ref_num < 5 && navigate("/affiliate")

        }
    }, [data])




    return (
        <BotContext.Provider value={{
            categories,
            setMounted,
            prices,
            prices2,
            senshyPricesTelegram,
            senshyPricesInstagram,
            affiliateId,
            loading,
            success,
            mounted}}>
            {children}
        </BotContext.Provider>
    );
};

export const useBot = () => useContext(BotContext);